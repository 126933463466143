<template>
  <div>
    <page-header>
    </page-header>
    <div class="layout-main-body">
      <el-card class="box-card" shadow="never">
        <div class="invoice-apply-body">
          <el-row>
            <el-col>
              <h3>请选择用户</h3>
              <remote-select v-model="editForm.userId"
                             width="300"
                             width-input="300px"
                             placement="bottom"
                             url="/user/list"
                             :columns="[{field:'nickName',name:'昵称'},{field:'mobile',name:'手机号'}]"
                             label="nickName"
                             value-key="id"
                             :size="5"
                             @change="handleUserChange"
                             clearable></remote-select>
            </el-col>
          </el-row>
          <el-row v-if="!!editForm.userId">
            <el-col :span="24">
              <h3>请确认发票抬头</h3>
              <el-table :data="invoiceHeaderInfo" style="width: 100%" border
                        @current-change="handleInvoiceHeaderTableRowSelected">
                <el-table-column width="55" align="center">
                  <template slot-scope="scope">
                    <el-radio v-model="currentInvoiceHeader" :label="scope.row"><span></span></el-radio>
                  </template>
                </el-table-column>
                <el-table-column prop="header" label="发票抬头" width="300"></el-table-column>
                <el-table-column prop="invoiceType" label="发票类型" width="120">
                  <template slot-scope="scope">
                    {{ scope.row.invoiceType === 1 ? '增值税普通发票' : '增值税专用发票' }}
                  </template>
                </el-table-column>
                <el-table-column prop="taxNumber" label="纳税人识别号"></el-table-column>
                <el-table-column prop="bankName" label="基本开户行"></el-table-column>
                <el-table-column prop="bankAccount" label="基本开户账号"></el-table-column>
                <el-table-column prop="companyAddress" label="企业注册地址"></el-table-column>
                <el-table-column prop="companyTel" label="企业联系电话"></el-table-column>
                <div slot="empty">
                  该用户没有维护发票抬头，
                  <router-link :to="`/oms/invoice/header`">
                    <el-button type="text">去维护</el-button>
                  </router-link>
                </div>
              </el-table>
            </el-col>
          </el-row>
          <el-row v-if="!!editForm.userId">
            <el-col>
              <h3>请确认订单信息</h3>
              <el-table :data="orderInfo" style="width: 100%" border @current-change="handleOrderTableRowSelected">
                <el-table-column width="55" align="center">
                  <template slot-scope="scope">
                    <el-radio v-model="currentOrder" :label="scope.row"><span></span></el-radio>
                  </template>
                </el-table-column>
                <el-table-column prop="orderNo" label="订单编号" width="300"></el-table-column>
                <el-table-column prop="totalAmount" label="订单金额"></el-table-column>
                <el-table-column prop="orderStatus" label="支付状态">
                  <template slot-scope="scope">
                    {{ scope.row.orderStatus === 10 ? '待付款' : scope.row.orderStatus === 20 ? '已取消' : '已完成' }}
                  </template>
                </el-table-column>
                <el-table-column prop="payWay" label="支付方式"></el-table-column>
                <div slot="empty">
                  暂无可开票的订单信息
                </div>
              </el-table>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <h3>请选择发票介质</h3>
              <el-radio v-model="editForm.invoiceMedia" :label="1">电子发票</el-radio>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <h3>系统备注</h3>
              <el-input type="textarea" style="width: 600px" :rows="4" placeholder="请输入内容"
                        v-model="editForm.remark"></el-input>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <h3>开票金额</h3>
              <el-row>
                <el-col :span="4">
                  <span class="money-title">订单金额: </span><span class="money">{{ editForm.amountOrder }}</span> 元
                </el-col>
              </el-row>
              <el-row>
                <el-col>
                  <el-col :span="4">
                    <span class="money-title">其他金额:</span>
                    <el-input v-model="editForm.amountOther" placeholder="请输入金额" class="other-money-box"></el-input>
                  </el-col>
                  <el-col :span="20">
                    <el-tag
                        class="amount-tag"
                        v-for="item in amountItems"
                        :key="item.label"
                        type="warning"
                        size="medium"
                        @click="handleAmountTipsClick(item.label)"
                        effect="dark">
                      {{ item.label }}
                    </el-tag>
                  </el-col>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-divider></el-divider>
          <el-row>
            <el-col>
              <span class="money-title">总开票金额：</span><span class="money">{{ editForm.amount }}</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-button type="primary" @click="handleSubmit" :loading="loadingSubmit">提交申请</el-button>
            </el-col>
          </el-row>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { UserInvoiceApi, UserInvoiceHeaderApi, OmsOrderApi } from '@/api'

const defaultForm = () => {
  return {
    userId: '',
    userInvoiceHeaderId: '',
    omsOrderId: '',
    remark: '',
    invoiceMedia: 1,
    amountOrder: '',
    amountOther: '',
    amount: ''
  }
}

export default {
  name: 'InvoiceApply',
  data () {
    return {
      orderInfo: [],
      invoiceHeaderInfo: [],
      editForm: defaultForm(),
      showing: true,
      amountItems: [
        { label: '3600' },
        { label: '2280' },
        { label: '1800' },
        { label: '960' }
      ],
      loadingSubmit: false,
      currentOrder: {},
      currentInvoiceHeader: {}
    }
  },
  watch: {
    'editForm.amountOther' (val) {
      if (val) {
        this.editForm.amountOther = this.filterInput(val)
      } else {
        this.editForm.amountOther = 0
      }
      this.calcInvoiceTotalAmount()
    },
    'editForm.amountOrder' () {
      this.calcInvoiceTotalAmount()
    }
  },
  methods: {
    handleUserChange (val) {
      this.editForm = defaultForm()
      this.editForm.userId = val.id
      this.getInvoiceHeader()
      this.getOmsOrder()
    },
    /**
     * 过滤中文等不合法的输入
     * @param val
     * @returns {*}
     */
    filterInput (val) {
      return val.replace(/\D/g, '').replace(/^0{1,}/g, '')
    },
    /**
     * 获取订单信息
     */
    getOmsOrder () {
      const params = {
        userId: this.editForm.userId,
        page: 0,
        size: 10
      }
      OmsOrderApi.list(params).then(res => {
        const rows = res.rows
        if (rows && rows.length > 0) {
          this.orderInfo = rows.filter(it => it.billStatus !== 3)
        }
      })
    },
    getInvoiceHeader () {
      const params = {
        userId: this.editForm.userId,
        page: 0,
        size: 10
      }
      UserInvoiceHeaderApi.list(params).then(res => {
        this.invoiceHeaderInfo = res.rows
      })
    },
    handleInvoiceHeaderTableRowSelected (val) {
      this.editForm.userInvoiceHeaderId = val.id
      this.currentInvoiceHeader = val
    },
    /**
     * 选择订单
     */
    handleOrderTableRowSelected (val) {
      this.editForm.omsOrderId = val.id
      this.editForm.amountOrder = val.totalAmount
      this.currentOrder = val
    },
    handleSubmit () {
      if (this.editForm.userId.length === 0) {
        this.$message.error('请选择用户')
        return
      }
      if (this.editForm.userInvoiceHeaderId.length === 0) {
        this.$message.error('请选择发票抬头')
        return
      }
      if (this.editForm.omsOrderId.length === 0) {
        this.$message.error('请选择订单')
        return
      }

      if (!this.editForm.amount) {
        this.$message.error('开票金额不能为空')
        return
      }

      this.$confirm('是否确认提交申请?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.showing = true
        this.loadingSubmit = true
        UserInvoiceApi.save(this.editForm).then(() => {
          this.loadingSubmit = false
          this.$confirm('提交成功，请等待财务审核', '提示').then(() => {
            location.reload()
          })
        })
      })
    },
    handleAmountTipsClick (val) {
      this.editForm.amountOther = val
    },
    calcInvoiceTotalAmount () {
      const {
        amountOrder,
        amountOther
      } = this.editForm
      const amountOrderInt = amountOrder ? parseInt(amountOrder) : 0
      const amountOtherInt = amountOther ? parseInt(amountOther) : 0
      this.editForm.amount = String((amountOrderInt * 100 + amountOtherInt * 100) / 100)
    }
  }
}
</script>

<style scoped lang="less">
.box-card p {
  font-size: 10px;
}

.box-card b {
  font-size: 18px;
}

.box-card {
  font-size: 10px;
}

.success-later {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: white;
  z-index: 9999;
}

.invoice-apply-body {
  padding: 10px 20px;

  h3 {
    font-size: 16px;
  }

  .el-row {
    margin-bottom: 10px;
  }

  .other-money-box {
    width: 130px;
    margin-left: 20px;
  }

  .amount-tag {
    height: 32px;
    line-height: 32px;
    margin-left: 10px;
    cursor: pointer;
    width: 60px;
    text-align: center;
    border: 0;
  }

  .money-title {
    font-size: 14px;
  }

  .money {
    font-size: 16px;
    font-weight: 600;
    color: #f14343;
    margin-left: 20px;
  }
}
</style>
